<template>
  <v-app>
    <v-main>
      <v-container fluid class="fill-height">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Auth'
}
</script>

<style scoped>

</style>
